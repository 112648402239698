import React from "react";
import tw from "twin.macro";
import AssignJob from "../assets/images/jobAssignment.png";
import AssignJobMobile from "../assets/images/assignJobMobile.png";

const TextContent = tw.div`justify-center items-center`;
const Problem = tw.div`w-11/12 mb-5`;
const Solution = tw.div`w-11/12 mt-3`;
const CTAText = tw.div`text-xl md:text-2xl mb-2 pt-10`;
const SolutionText = tw.div`w-11/12 text-xl md:text-2xl`;
const RightText = tw.div`text-xl md:text-2xl mt-16 ml-4`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-2 text-2xl font-bold inline-block rounded-xl`;
const primaryButtonUrl = "https://production.uclusion.com/?utm_campaign=team#signup";
const LeftAndRight = tw.div`flex justify-between`;

const BelowTheFoldJira = (properties) => {
    const { isMobile } = properties;
    return (
        <TextContent style={{display: "flex", flexDirection: "column"}}>
            <Problem>
                <SolutionText style={{display: isMobile ? 'none' : undefined}}>
                    The three levels of bugs and two levels of jobs send notifications based on severity.
                </SolutionText>
            </Problem>
            <Solution>
                <LeftAndRight>
                    <img src={isMobile ? AssignJobMobile : AssignJob} alt='Assign job' />
                    <RightText style={{display: isMobile ? 'none' : undefined}}>
                        Critical notifications cannot be dismissed and all notifications have wizards that guide
                        completion.
                    </RightText>
                </LeftAndRight>
                <CTAText style={{paddingTop: isMobile ? '1rem' : undefined}}>
                    See <em>administrivia</em> handled in the <PrimaryButton as="a" href={primaryButtonUrl}>sandbox demo</PrimaryButton> after
                    free sign up.
                </CTAText>
            </Solution>
        </TextContent>
    );
};

export default BelowTheFoldJira;
