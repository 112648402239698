import React from "react";
import videoMobile from '../assets/swimlanesMobile.mp4';
import tw from "twin.macro";

const SubHeading = tw.div`text-center font-normal h-24 lg:h-12`;
const CTA = tw.div`text-center font-normal text-2xl`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-2 text-2xl font-bold inline-block rounded-xl`;
const primaryButtonUrl = "https://production.uclusion.com/?utm_campaign=team#signup";

const BelowTheFoldDemoTeaser = (properties) => {
    const { isMobile } = properties;
    if (isMobile) {
        return (
            <>
                <video id='aboveTheFoldVideo' style={{borderRadius: '15px', marginTop: '5rem',
                    marginLeft: 'auto', marginRight: 'auto'}} autoPlay muted loop controls
                >
                    <source
                        src={videoMobile}
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
                <SubHeading style={{marginTop: '1rem'}}>
                    <CTA style={{fontSize: '1rem'}}>
                        <PrimaryButton as="a" href={primaryButtonUrl}>Signup</PrimaryButton> without credit card to
                        play with this sandbox demo of a team doing better than Slack and Jira.
                    </CTA>
                </SubHeading>
            </>
        );
    }
    return (
        <div style={{display: 'flex', marginLeft: 'auto', marginRight: 'auto', marginTop: '3rem'}}>
            <SubHeading style={{maxWidth: '20rem', paddingRight: '2rem'}}>
                <CTA style={{paddingTop: '1rem'}}>
                    <PrimaryButton as="a" href={primaryButtonUrl}>Signup</PrimaryButton> without credit card to
                    play with this sandbox demo of a team doing better than Slack and Jira.
                </CTA>
            </SubHeading>
            <video id='aboveTheFoldVideo' style={{borderRadius: '15px'}} width="1100" height="330" autoPlay muted loop>
                <source
                    src={videoMobile}
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default BelowTheFoldDemoTeaser;