import React from "react";
import tw from "twin.macro";
import Inbox from "../assets/images/inbox.png";
import InboxMobile from "../assets/images/inboxMobile.png";

const TextContent = tw.div`justify-center items-center`;
const Problem = tw.div`w-11/12`;
const Solution = tw.div`w-11/12 mt-2`;
const SolutionText = tw.div`w-11/12 text-xl md:text-2xl`;
const CTAText = tw.div`text-xl pt-5`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-2 text-2xl font-bold inline-block rounded-xl`;
const primaryButtonUrl = "https://production.uclusion.com/?utm_campaign=team#signup";

const BelowTheFoldInbox = (properties) => {
    const { isMobile } = properties;
    return (
        <TextContent style={{display:"flex", flexDirection:"column"}}>
            <Problem>
                <SolutionText style={{display: isMobile ? 'none' : undefined}}>
                    Every notification is backed by a helpful wizard and the same for anything you need a response
                    on in the From You tab.
                </SolutionText>
            </Problem>
            <Solution>
                <img src={isMobile ? InboxMobile : Inbox} alt='Inbox'
                     style={{maxHeight: isMobile ? undefined : '38rem'}}/>
                <CTAText style={{display: isMobile ? undefined : 'none'}}>
                    See a full inbox in the <PrimaryButton as="a" href={primaryButtonUrl}>sandbox demo</PrimaryButton> after
                    free sign up.
                </CTAText>
            </Solution>
        </TextContent>
    );
};

export default BelowTheFoldInbox;