import React, {useState} from "react";
import tw from "twin.macro";
import BelowTheFoldScrum from "./BelowTheFoldScrum";
import BelowTheFoldJira from "./BelowTheFoldJira";
import BelowTheFoldSlack from "./BelowTheFoldSlack";
import BelowTheFoldIssues from "./BelowTheFoldIssues";
import BelowTheFoldScale from "./BelowTheFoldScale";
import BelowTheFoldSolo from "./BelowTheFoldSolo";
import BelowTheFoldStatus from "./BelowTheFoldStatus";
import BelowTheFoldInbox from "./BelowTheFoldInbox";

const Container = tw.div`h-screen`;
const ScalableContainer = tw.div`flex justify-center items-center`;
const LeftAndRight = tw.div`w-4/5 flex justify-between`;
const LeftNav = tw.div`w-1/5 flex-col justify-center items-end mt-12 mr-2`;
const RightPage = tw.div`w-4/5 mt-16 pt-8 pl-3 pb-5 border-solid border border-black rounded-xl`;
export const TabLink = tw.a`
  text-2xl tracking-wide transition duration-300 cursor-pointer
  border-b-2 hover:border-black hover:border-primary-500 text-primary-500
`;
export const SelectedTab = tw.div`
  text-2xl tracking-wide transition duration-300 
`;
const ButtonContainer = tw.div`w-full mt-10`;

const BelowTheFold = (properties) => {
    const { isMobile } = properties;
    const [visiblePage, setVisiblePage] = useState('jira');
    const ScrumButton = visiblePage === 'scrum' ? SelectedTab : TabLink;
    const SlackButton = visiblePage === 'slack' ? SelectedTab : TabLink;
    const JiraButton = visiblePage === 'jira' ? SelectedTab : TabLink;
    const ScaleButton = visiblePage === 'scale' ? SelectedTab : TabLink;
    const SoloButton = visiblePage === 'solo' ? SelectedTab : TabLink;
    const IssuesButton = visiblePage === 'issues' ? SelectedTab : TabLink;
    const StatusButton = visiblePage === 'status' ? SelectedTab : TabLink;
    const InboxButton = visiblePage === 'inbox' ? SelectedTab : TabLink;

    if (isMobile) {
        return <div style={{marginTop: '7rem'}}>
            <BelowTheFoldIssues isMobile/>
            <div style={{paddingBottom: '3rem'}}/>
            <BelowTheFoldInbox isMobile/>
            <div style={{paddingBottom: '3rem'}}/>
            <BelowTheFoldScale isMobile/>
            <div style={{paddingBottom: '3rem'}}/>
            <BelowTheFoldJira isMobile/>
            <div style={{paddingBottom: '3rem'}}/>
            <BelowTheFoldStatus isMobile/>
            <div style={{paddingBottom: '3rem'}}/>
            <BelowTheFoldSlack isMobile/>
            <div style={{paddingBottom: '3rem'}}/>
            <BelowTheFoldScrum isMobile/>
            <div style={{paddingBottom: '3rem'}}/>
            <BelowTheFoldSolo isMobile/>
        </div>;
    }

    return (
        <Container>
            <ScalableContainer>
            <LeftAndRight>
                        <LeftNav>
                            <ButtonContainer>
                                <JiraButton onClick={() => setVisiblePage('jira')}>Bug and job assignment</JiraButton>
                            </ButtonContainer>
                            <ButtonContainer>
                                <InboxButton onClick={() => setVisiblePage('inbox')}>Wizard powered inbox</InboxButton>
                            </ButtonContainer>
                            <ButtonContainer>
                                <ScrumButton onClick={() => setVisiblePage('scrum')}>Job approval</ScrumButton>
                            </ButtonContainer>
                            <ButtonContainer>
                                <SoloButton onClick={() => setVisiblePage('solo')}>Job estimation and review</SoloButton>
                            </ButtonContainer>
                            <ButtonContainer>
                                <IssuesButton onClick={() => setVisiblePage('issues')}>Job assistance</IssuesButton>
                            </ButtonContainer>
                            <ButtonContainer>
                                <StatusButton onClick={() => setVisiblePage('status')}>Status at a glance</StatusButton>
                            </ButtonContainer>
                            <ButtonContainer>
                                <SlackButton onClick={() => setVisiblePage('slack')}>Slack integration</SlackButton>
                            </ButtonContainer>
                            <ButtonContainer>
                                <ScaleButton onClick={() => setVisiblePage('scale')}>Customer workspace</ScaleButton>
                            </ButtonContainer>
                        </LeftNav>
                        <RightPage style={{minHeight: '40rem'}}>
                            {visiblePage === 'scrum' && <BelowTheFoldScrum/>}
                            {visiblePage === 'slack' && <BelowTheFoldSlack/>}
                            {visiblePage === 'jira' && <BelowTheFoldJira/>}
                            {visiblePage === 'issues' && <BelowTheFoldIssues/>}
                            {visiblePage === 'status' && <BelowTheFoldStatus/>}
                            {visiblePage === 'scale' && <BelowTheFoldScale/>}
                            {visiblePage === 'solo' && <BelowTheFoldSolo/>}
                            {visiblePage === 'inbox' && <BelowTheFoldInbox/>}
                        </RightPage>
                    </LeftAndRight>
                </ScalableContainer>
        </Container>
    );
};

export default BelowTheFold;