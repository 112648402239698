import React from "react";
import tw from "twin.macro";
import "./bounce.css";
import BelowTheFoldDemoTeaser from "./BelowTheFoldDemoTeaser";
const Container = tw.div`pt-24 w-full flex justify-center items-center`;
const TextContent = tw.div`text-center md:text-left mr-5 ml-5`;
const Heading = tw.div`font-black text-left text-4xl lg:text-4xl text-center leading-tight tracking-wide`;
const SubHeading = tw.div`text-center font-normal h-24 lg:h-12`;
const CTA = tw.div`text-center font-normal text-2xl`;
const HeadingHighlight = tw.span`text-primary-500`;

const AboveTheFold = (properties) => {
    const { isMobile } = properties;

    return (
        <Container>
            <TextContent>
                <Heading style={{fontSize: isMobile ? '1.8rem' : undefined, marginTop: isMobile ? '2rem' : undefined}}>
                    <HeadingHighlight>Developer communication</HeadingHighlight> built ground up for better collaboration.
                </Heading>
                <SubHeading>
                    <CTA style={{paddingTop: isMobile ? '2rem': '1rem'}}>
                        Instead of helping you make decisions, your current task management just encourages more meetings.
                    </CTA>
                </SubHeading>
                <BelowTheFoldDemoTeaser isMobile={isMobile} />
            </TextContent>
        </Container>
    );
};

export default AboveTheFold;