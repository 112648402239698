import React from "react";
import tw from "twin.macro";
import Progress from "../assets/images/progress.png";
import ProgressMobile from "../assets/images/progressMobile.png";

const TextContent = tw.div`justify-center items-center mr-2 ml-5`;
const Problem = tw.div`w-11/12 mb-4`;
const Solution = tw.div`w-11/12 mt-8`;
const ProblemText = tw.div`w-11/12 text-xl md:text-2xl`;
const CTAText = tw.div`text-xl md:text-2xl mb-2 pt-20`;
const LeftAndRight = tw.div`flex justify-between`;
const LeftText = tw.div`text-xl md:text-2xl mt-16 mr-4`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-2 text-2xl font-bold inline-block rounded-xl`;
const primaryButtonUrl = "https://production.uclusion.com/?utm_campaign=team#signup";

const BelowTheFoldSolo = (properties) => {
    const { isMobile } = properties;
    return (
        <TextContent style={{display:"flex", flexDirection:"column"}}>
            <Problem>
                <ProblemText style={{display: isMobile ? 'none' : undefined}}>
                    Estimates are required after approval but can be changed anytime.
                </ProblemText>
            </Problem>
            <Solution>
                <LeftAndRight>
                    <LeftText style={{display: isMobile ? 'none' : undefined}}>A progress report gets feedback and
                        replaces an estimate.</LeftText>
                    <img src={isMobile ? ProgressMobile : Progress} alt='Progress'
                         style={{maxWidth: isMobile ? undefined : '50rem'}} />
                </LeftAndRight>
                <CTAText style={{paddingTop: isMobile ? '1rem' : undefined}}>
                    See a team using required reviews in the sandbox demo after free <PrimaryButton as="a" href={primaryButtonUrl}>sign up</PrimaryButton>.
                </CTAText>
            </Solution>
        </TextContent>
    );
};

export default BelowTheFoldSolo;